import useEffect from '@hooks/useEffect'
import useTrack from '@hooks/useTrack'

export default function Track(props) {
  const track = useTrack()
  useEffect(() => {
    track('viewProduct', {productId: props.productId})
  }, [])
  return null
}
