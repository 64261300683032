import useAnalyticLocation from '@hooks/useAnalyticLocation'
import useLazyAnalyticsV2 from '../hooks/useLazyAnalyticsV2'
import {INTERNAL_EVENTS} from '../types/analyticsServicesTypes'

export interface TrackProductEditedProps {
  item: any
  menuId: string
}

export const useTrackProductEdit = () => {
  const analyticsV2 = useLazyAnalyticsV2()
  const actionLocation = useAnalyticLocation('editProduct')
  const track = (props: TrackProductEditedProps) => {
    const {item, menuId} = props
    if (!item) return null
    analyticsV2.trackEventAsDefaultOnInternal(INTERNAL_EVENTS.productEdited, {
      productEditAction: item?.amount === 0 ? 'delete' : 'edit',
      productId: item?.product?._id ?? item?.productId,
      productAmount: item?.amount,
      isAmountEdited: true,
      isOutOfStock: item?.isOutOfStock,
      menuId: menuId ?? '',
      actionLocation: actionLocation,
    })
  }
  return {track}
}
