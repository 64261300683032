import Button from '@components/Button'
import DeliveryWizard from '@components/DeliveryWizard'
import usePreferencesForAddressContext from '@components/DeliveryWizard/internals/hooks/usePreferencesForAddressContext.main'
import SafeSuspenseLoading from '@components/SafeSuspenseLoading'
import {Product} from '@data/__generated__/types.main'
import {withRefetchCart} from '@data/queries/preferences/refetchCart'
import {EVENTS} from '@helpers/analytics/events'
import {PurchaseTaxonomies} from '@helpers/analytics/events/purchase'
import {sendEvent} from '@helpers/analytics/sendEvents'
import classnames from '@helpers/misc/classnames'
import useAnalyticLocation from '@hooks/useAnalyticLocation'
import useEventsBaseProperties from '@hooks/useEventsBaseProperties'
import useIsDarkMode from '@hooks/useIsDarkMode'
import FormatNumber from '@i18n/formatNumber/Component'
import Tooltip from '@packages/justo-parts/lib/components/Tooltip'
import {useTranslation} from 'next-i18next'
import React, {useCallback, useState} from 'react'
import {MdAdd as AddIcon, MdShoppingCart, MdRemove as RemoveIcon} from 'react-icons/md'

import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'
import {useTrackProductEdit} from '@page-components/AnalyticsV2/tracking-hooks/useTrackProductEdit'
import Delete from './Delete'
import TiramisuButton from './TiramisuButton'
import useSelectProductCartItems from './hooks/useCartItems.main'
import styles from './styles.module.css'

export const translatePrefix = 'order.products.productsContent.selectProduct.inner.actions'

export interface ActionsProps {
  state: any
  resume: any
  add: any
  close: any
  onChange: any
  product: Product
  isOutOfStock: boolean
  isAddingToCart: boolean
  refetchCart?: () => Promise<void>
  allowEdit?: boolean
  layout?: string
}

const Actions: React.FC<ActionsProps> = props => {
  const {t} = useTranslation()
  const {cart, menuId} = useSelectProductCartItems() ?? {}
  const {preferences} = usePreferencesForAddressContext()
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false)
  const cartItem = props.allowEdit
    ? cart?.items.find(item => item.productId === props.product._id)
    : null
  const baseEventProperties = useEventsBaseProperties()
  const actionLocation = useAnalyticLocation('editProduct')
  const isDarkMode = useIsDarkMode()
  const containerClass = isDarkMode ? styles.containerDark : styles.container
  const amounButtonClass = isDarkMode
    ? classnames(styles.amountButton, styles.amountButtonDark)
    : styles.amountButton
  const isAnalyticsV2Avail = useIsAnalyticsV2Avail()
  const trackProductEdit = useTrackProductEdit()

  const renderButton = useCallback(
    (adds, {maxPurchaseQuantity, cartItem}) => {
      const Icon = adds ? AddIcon : RemoveIcon
      const diff = adds ? 1 : -1
      return (
        <div
          className={amounButtonClass}
          onClick={() => {
            const amount = props.state.amount + diff

            if (amount === 0) return
            if (maxPurchaseQuantity && amount > maxPurchaseQuantity) return
            if (cartItem && maxPurchaseQuantity && cartItem.amount + amount > maxPurchaseQuantity)
              return
            props.onChange({amount: amount})
          }}
        >
          <Icon size={20} />
        </div>
      )
    },
    [props.state, props.product, props.onChange],
  )

  const renderPrice = useCallback(() => {
    const {price} = props.resume
    if (!price) return null

    return <FormatNumber value={Math.max(price, 0)} />
  }, [props.resume])

  const renderNoStore = useCallback(() => {
    return (
      <div className={styles.options}>
        <div>{t(`${translatePrefix}.labelAddAddressOrSelectStore`)}</div>
        <br />
        <div>
          <SafeSuspenseLoading>
            <DeliveryWizard.AsComponent />
          </SafeSuspenseLoading>
        </div>
      </div>
    )
  }, [])

  const renderDelete = useCallback(() => {
    if (!cartItem) return null
    return <Delete cartItem={cartItem} close={props.close} menuId={menuId} />
  }, [cartItem, props.close])

  const renderNotAvailable = useCallback(() => {
    const message =
      props.product.availabilityAt.notAvailableMessage ||
      t(`${translatePrefix}.labelProductNotAvailable`)
    return (
      <div className={containerClass}>
        <div className={styles.outOfStockMessage}>{message}</div>
        {renderDelete()}
      </div>
    )
  }, [props.product, renderDelete])

  const renderAddButton = useCallback(() => {
    const tooltipText = cartItem ? 'edit' : 'add'
    return (
      <Button
        loading={props.isAddingToCart}
        className={classnames(styles.button, 'justify-between')}
        primary
        onClick={async () => {
          if (!preferences?.storeId) {
            setIsAddressModalOpen(true)
            return
          }
          props.add()
          if (cartItem) {
            if (!isAnalyticsV2Avail) {
              sendEvent<PurchaseTaxonomies['productEdited']>(EVENTS.purchase.productEdited, {
                ...baseEventProperties,
                productEditAction: 'edit',
                productId: cartItem?.productId ?? '',
                productAmount: props?.state?.amount,
                isAmountEdited: true,
                isOutOfStock: cartItem?.isOutOfStock ?? false,
                menuId: menuId ?? '',
                actionLocation: actionLocation,
              })
            } else {
              trackProductEdit.track({item: {...cartItem, amount: props?.state?.amount}, menuId})
            }
            await props.refetchCart?.()
          }
        }}
      >
        <div className="flex justify-between flex-row w-full">
          <span className="flex gap-2 items-center">
            <Tooltip
              content={!props.resume.ready ? t(`${translatePrefix}.labelCompleteAll`) : null}
            >
              <MdShoppingCart size={18} aria-label="cart" />{' '}
              {t(`fields.${tooltipText}`, {ns: 'generic'})}
            </Tooltip>
          </span>
          <span>{renderPrice()}</span>
        </div>
      </Button>
    )
  }, [props.isAddingToCart, props.add, cartItem, props.refetchCart, props.resume.ready])

  const renderOutOfStock = useCallback(() => {
    return (
      <div className={containerClass}>
        <div className={styles.outOfStockMessage}>
          {t(`${translatePrefix}.labelProductOutOfStock`)}
        </div>
        {renderDelete()}
      </div>
    )
  }, [renderDelete])

  const renderMaxPurchaseQuantity = useCallback(
    maxPurchaseQuantity => {
      return (
        <div className={containerClass}>
          <div className={styles.outOfStockMessage}>
            {t(`${translatePrefix}.labelMaxPurchaseQuantity`, {max: maxPurchaseQuantity})}
          </div>
          {renderDelete()}
        </div>
      )
    },
    [renderDelete],
  )

  const renderTiramisuButton = useCallback(() => {
    return (
      <TiramisuButton
        cartItem={cartItem}
        add={props.add}
        isAddingToCart={props.isAddingToCart}
        refetchCart={props.refetchCart}
        resume={props.resume}
      />
    )
  }, [cartItem, props.add, props.isAddingToCart, props.refetchCart, props.resume])

  const {amount} = props.state
  const {maxPurchaseQuantity} = props.product

  if (props.isOutOfStock) return renderOutOfStock()
  if (!props.product.availabilityAt) return renderNoStore()
  if (!props.product.availabilityAt.available) return renderNotAvailable()
  if (cartItem && maxPurchaseQuantity && cartItem.amount >= maxPurchaseQuantity)
    return renderMaxPurchaseQuantity(maxPurchaseQuantity)
  return (
    <div className={containerClass}>
      <div className={styles.inner}>
        <div className={styles.amount}>
          {renderButton(false, {maxPurchaseQuantity, cartItem})}
          <div className={styles.amountValue}>{amount}</div>
          {renderButton(true, {maxPurchaseQuantity, cartItem})}
        </div>
        {props.layout === 'tiramisu' ? renderTiramisuButton() : renderAddButton()}
      </div>
      {renderDelete()}
      {isAddressModalOpen ? (
        <DeliveryWizard.AsModal
          isOpen={isAddressModalOpen}
          highZIndex
          canClickOutside={false}
          onClose={() => {
            setIsAddressModalOpen(false)
          }}
          actionLocation="product-details"
        />
      ) : null}
    </div>
  )
}

export default withRefetchCart(Actions)
