import cartItemFragment from '@data/fragments/cartFragment/cartItemFragment.main'
import useWebsiteId from '@hooks/useWebsiteId'
import {useClient} from 'apollo-hooks'
import gql from 'graphql-tag'

export const refetchCartQuery = gql`
  query getCartAndPrices($websiteId: ID) {
    preferences: userPreferences(websiteId: $websiteId) {
      _id
      cart {
        items {
          _id
          ...resumeCartItem
        }
        itemsPrice
        realPrice: itemsPrice(includeDiscount: false)
        totalPrice
        amountToPay
        deliveryFee
        serviceFee
        subtotal: itemsPrice(
          includeDiscount: true
          includeCoupon: true
          includeJustoCoins: false
          includeLoyaltyDiscount: false
          includeWebsiteCoins: false
        )
        subtotalWithProductDiscount: itemsPrice(
          includeDiscount: true
          includeCoupon: false
          includeJustoCoins: false
          includeLoyaltyDiscount: false
          includeWebsiteCoins: false
        )
      }
    }
  }
  ${cartItemFragment}
`

export const useRefetchCart = () => {
  const client = useClient()
  const websiteId = useWebsiteId()

  return async () => {
    await client.query({
      query: refetchCartQuery,
      variables: {websiteId},
      fetchPolicy: 'network-only',
    })
  }
}

// eslint-disable-next-line react/display-name
export const withRefetchCart = Component => (props: any) => {
  const refetchCart = useRefetchCart()

  return <Component {...props} refetchCart={refetchCart} />
}
