const hasAtLeastOneModifierOption = (optionsIds: string[], modifierOption: any): boolean => {
  return optionsIds
    .map(optionsIdSelected => {
      return modifierOption.requiresModifierOptionIds.includes(optionsIdSelected)
    })
    .includes(true)
}

export default function getFilteredModifiers(modifiers: any, values: any) {
  return modifiers
    .map((modifierGroup, index) => {
      const allModifierOptions = modifierGroup.options || []
      const modifierOptions = allModifierOptions.filter(modifierOption => {
        if (!modifierOption.availabilityAt?.available) return false

        if (modifierOption?.requiresModifierOptionIds?.length) {
          for (const requiredOptionId of modifierOption?.requiresModifierOptionIds || []) {
            const hasThisOption = !!values.modifiers?.find(modifier => {
              if (modifierOption.requiresAtLeastOneModifierOption) {
                return hasAtLeastOneModifierOption(modifier.optionsIds, modifierOption)
              }

              return modifier.optionsIds?.includes(requiredOptionId)
            })

            if (!hasThisOption) return false
          }
        }

        return true
      })

      return {
        index,
        ...modifierGroup,
        options: modifierOptions
      }
    })
    .filter(modifierGroup => modifierGroup.options?.length > 0)
}
