import React from 'react'
import Button from '@components/Button'
import Image from '@components/Image'
import Tooltip from '@packages/justo-parts/lib/components/Tooltip'
import {useTranslation} from 'next-i18next'

import {translatePrefix} from '../../Actions'

import Price from './Price'

import styles from './styles.module.css'

export default function TiramisuButton({cartItem, isAddingToCart, add, refetchCart, resume}) {
  const {t} = useTranslation('website')

  return (
    <div className={'flex justify-center items-center w-full'}>
      <div className={'flex flex-row'}>
        <Button
          loading={isAddingToCart}
          className={styles.button}
          onClick={async () => {
            add()
            if (cartItem) {
              await refetchCart?.()
            }
          }}>
          <Tooltip content={!resume.ready ? t(`${translatePrefix}.labelCompleteAll`) : null} />
          <Image
            src="/images/tiramisu/shopping-basket.svg"
            alt="shopping basket"
            width={40}
            height={40}
          />
          <div
            className={
              'flex justify-center items-center font-bold text-vibrant-red text-xl px-2.5'
            }>
            <Price resume={resume} />
          </div>
        </Button>
      </div>
    </div>
  )
}
