import React from 'react'
import {MdAddCircleOutline, MdRemoveCircleOutline} from 'react-icons/md'
import formatNumber from '@i18n/formatNumber'

import styles from './styles.module.css'

export default function Count(props) {
  return (
    <div className={styles.activeContainer}>
      {props.count > 0 ? (
        <>
          <div
            className={props.count > 0 ? styles.icon : styles.iconDisabled}
            onClick={props.subtract}
            role="button"
            aria-label="remove_button"
            aria-disabled={props.count === 0}>
            <MdRemoveCircleOutline size={24} aria-label="remove_icon" />
          </div>
          <div className={styles.count} aria-label="option_count">
            {formatNumber(props.count, '0,0')}
          </div>
        </>
      ) : null}
      <div
        className={props.canAdd ? styles.icon : styles.iconDisabled}
        onClick={props.add}
        role="button"
        aria-label="add_button"
        aria-disabled={!props.canAdd}>
        <MdAddCircleOutline size={24} aria-label="add_icon" />
      </div>
    </div>
  )
}
