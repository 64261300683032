export default function getMergedModifiers(productCached, productNonCached) {
  const {modifiers: productCachedModifiers} = productCached
  const {modifiers: productNonCachedModifiers} = productNonCached

  return productCachedModifiers.map(productCachedModifier => {
    const productNonCachedModifier = productNonCachedModifiers.find(
      modifier => modifier._id === productCachedModifier._id
    )

    const {options: productCachedModifierOptions} = productCachedModifier
    const {options: productNonCachedModifierOptions} = productNonCachedModifier

    const options = productCachedModifierOptions.map(productCachedModifierOption => {
      const productNonCachedModifierOption = productNonCachedModifierOptions.find(
        option => option._id === productCachedModifierOption._id
      )

      return {...productCachedModifierOption, ...productNonCachedModifierOption}
    })

    return {...productCachedModifier, options}
  })
}
