const stamps = {
  VEGAN: {
    URL: 'https://s3.amazonaws.com/orion-eat-app-files/buckets-prod%2FYNHhFnJ49oaeNo4bh-VEGAN.svg',
    label: 'Vegano'
  },
  VEGETARIAN: {
    URL: 'https://s3.amazonaws.com/orion-eat-app-files/buckets-prod%2FTtMZJWqFeoM6jXZPT-VEGETARIAN.svg',
    label: 'Vegetariano'
  },
  CACAO_100: {
    URL: 'https://s3.amazonaws.com/orion-eat-app-files/buckets-prod%2FvyL8X6C4BMa3GmGPB-COCOA_100.svg',
    label: '100% cacao'
  },
  GLUTEN_FREE: {
    URL: 'https://s3.amazonaws.com/orion-eat-app-files/buckets-prod%2FpAkb6dT9J5BCNdw9b-GLUTEN_FREE.svg',
    label: 'Sin gluten'
  },
  LACTOSE_FREE: {
    URL: 'https://s3.amazonaws.com/orion-eat-app-files/buckets-prod%2FXN85u2RmNmZzZbXwm-LACTOSE_FREE.svg',
    label: 'Sin lactosa'
  },
  SUGAR_FREE: {
    URL: 'https://s3.amazonaws.com/orion-eat-app-files/buckets-prod%2F9tFGhk4Yeo7CGrb4p-SUGAR_FREE.svg',
    label: 'Sin azúcar'
  },
  RECYCLABLE: {
    URL: 'https://s3.amazonaws.com/orion-eat-app-files/buckets-prod%2FuytNnxyQ8HnKkJgqe-RECYCLABLE.svg',
    label: 'Reciclable'
  },
  SPICY: {
    URL: 'https://s3.amazonaws.com/orion-eat-app-files/buckets-prod%2FRo6uAC2RWMGg3Tqa7-SPICY.svg',
    label: 'Picante'
  },
  RECYCLED: {
    URL: 'https://s3.amazonaws.com/orion-eat-app-files/buckets-prod%2Ft2xxZt5dqeK5JxWSx-RECICLED.svg',
    label: 'Reciclado'
  },
  HANDMADE: {
    URL: 'https://s3.amazonaws.com/orion-eat-app-files/buckets-prod%2FDCynECNEfDyMFAaed-HANDMADE.svg',
    label: 'Hecho a mano'
  },
  OLDER_THAN_18: {
    URL: 'https://s3.amazonaws.com/orion-eat-app-files/buckets-prod%2FKKF3DBuShnTzrNrLz-OLDER_THAN_18.svg',
    label: 'Mayor de 18'
  },
  CRUELTY_FREE: {
    URL: 'https://s3.amazonaws.com/orion-eat-app-files/buckets-prod%2FyNBMHBvXPe5gjraTt-CRUELTY_FREE.svg',
    label: 'No testeado en animales'
  },
  KETO: {
    URL: 'https://s3.amazonaws.com/orion-eat-app-files/buckets-prod%2Fv99t5FTchxQkpjgY3-KETO.svg',
    label: 'Keto'
  }
}
export default stamps
