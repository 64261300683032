import usePageMeta from '@hooks/usePageMeta'
import getCurrencyCode from '@i18n/getCurrencyCode'
import {useRouter} from 'next/router'

export interface Props {
  product: any
}

export default function PageMeta(props: Props) {
  const {product} = props
  const router = useRouter()
  const image = product.images && product.images[0]

  const offer = product.availabilityAt
    ? {
        '@type': 'Offer',
        url: router.asPath,
        priceCurrency: getCurrencyCode(),
        price: product.availabilityAt.finalPrice
      }
    : null

  const schema = image
    ? {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        name: product.name,
        image: [image.imageLarge],
        sku: product._id,
        description: product.description,
        offers: offer
      }
    : null
  usePageMeta(props.product.name, props.product.description, image, schema)

  return null
}
