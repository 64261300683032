import gql from 'graphql-tag'

export default gql`
  mutation addProductToCart(
    $productId: ID
    $amount: Float
    $modifiers: [CartItemOptionInput]
    $comment: String
    $menuId: ID
  ) {
    item: addProductToCart(
      productId: $productId
      amount: $amount
      modifiers: $modifiers
      comment: $comment
      menuId: $menuId
    ) {
      _id
      totalPrice(includeDiscount: true)
      unitPrice
    }
  }
`
