import React from 'react'
import {MdCheckBox as CheckedIcon, MdCheckBoxOutlineBlank as UncheckedIcon} from 'react-icons/md'

import styles from './styles.module.css'
export default function Checkbox(props) {
  const Icon = props.count ? CheckedIcon : UncheckedIcon

  return (
    <div
      className={props.count || props.canAdd ? styles.container : styles.containerDisabled}
      onClick={props.toggle}>
      <Icon size={24} />
    </div>
  )
}
