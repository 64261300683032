import React from 'react'
import classnames from '@helpers/misc/classnames'
import {TFunction, withTranslation} from 'next-i18next'

import styles from '../styles.module.css'

const translatePrefix =
  'order.products.productsContent.selectProduct.inner.details.modifiers.comments'

export interface CommentsProps {
  value: string
  onChange: any
  t: TFunction
}

export class Text extends React.Component<CommentsProps> {
  render() {
    return (
      <div className={classnames(styles.container, "dark:border-zinc-700")}>
        <div className={styles.labelContainer}>
          <div className={styles.label}>
            {this.props.t(`${translatePrefix}.labelSpecialInstructions`)}
          </div>
        </div>
        <textarea
          value={this.props.value || ''}
          onChange={event => this.props.onChange(event.target.value)}
          className={classnames(styles.textarea, "dark:bg-zinc-600")}
          placeholder={this.props.t(`${translatePrefix}.labelIncludeNote`)}
          rows={2}
        />
      </div>
    )
  }
}

export default withTranslation()(Text)
