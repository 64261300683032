export default function getPricePerItem(product, state, includeDiscount = true) {
  if (!product?.availabilityAt) return

  let price = includeDiscount ? product.availabilityAt.finalPrice : product.availabilityAt.basePrice

  for (const {modifierId, optionsIds} of state.modifiers) {
    const modifier = product.modifiers.find(modifier => modifier._id === modifierId)
    if (!modifier) continue

    for (const optionId of optionsIds) {
      const option = modifier.options.find(option => option._id === optionId)
      if (!option) continue
      if (!option.availabilityAt) continue
      if (!option.availabilityAt.price) continue
      price += option.availabilityAt.price
    }
  }

  return price
}
