export default function getDefaultState(product, cartItem) {
  const productModifiers = product?.modifiers || []

  if (cartItem) {
    return {
      ...cartItem,
      modifiers: productModifiers.map(modifier => {
        const savedModifier = cartItem.modifiers.find(
          savedModifier => savedModifier.modifierId === modifier._id
        )
        return {
          modifierId: modifier._id,
          optionsIds: savedModifier ? savedModifier.optionsIds || [] : []
        }
      })
    }
  }

  const modifiers = productModifiers.map(modifier => {
    return {
      modifierId: modifier._id,
      optionsIds: []
    }
  })
  return {
    modifiers,
    comment: null,
    amount: 1
  }
}
