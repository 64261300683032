import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

import {
  getBlockMenuCommentsConfiguration_cached,
  getBlockMenuCommentsConfiguration_cached_sectionConfiguration_menu,
  getBlockMenuCommentsConfiguration_cachedVariables
} from './__generated__/getBlockMenuCommentsConfiguration_cached'

export default function useMenuConfiguration():
  | getBlockMenuCommentsConfiguration_cached_sectionConfiguration_menu
  | Record<string, any> {
  const websiteId = useWebsiteId()
  const {sectionConfiguration} = useApolloQuery<
    getBlockMenuCommentsConfiguration_cached,
    getBlockMenuCommentsConfiguration_cachedVariables
  >({
    query: gql`
      query getBlockMenuCommentsConfiguration_cached($websiteId: ID) {
        sectionConfiguration(section: "menu", websiteId: $websiteId) {
          _id
          menu {
            blockCommentsOnProducts
          }
        }
      }
    `,
    variables: {websiteId},
    fetchPolicy: 'cache-first'
  })

  if (!sectionConfiguration) return {}
  if (!sectionConfiguration.menu) return {}

  return sectionConfiguration.menu
}
