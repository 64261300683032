import React from 'react'
import classnames from '@helpers/misc/classnames'
import useIsDarkMode from '@hooks/useIsDarkMode'
import {useTranslation} from 'next-i18next'

import Option from './Option'
import SelectionInfo from './SelectionInfo'

import styles from './styles.module.css'

export default function Modifier(props) {
  const {product, modifier, value: optionsIds, onChange} = props
  const options = modifier.options.filter(
    option => option.availabilityAt && option.availabilityAt.available
  )
  const {t} = useTranslation('generic', {keyPrefix: 'fields'})
  const isDarkMode = useIsDarkMode()
  const {min: minSelection, max: maxSelection} = modifier
  const tagLabel = modifier.optional ? 'optional' : 'required'
  const tagClasses = `${isDarkMode ? classnames(styles.tag, styles.tagDark) : styles.tag} ${
    !modifier.optional ? (isDarkMode ? styles.notOptionalDark : styles.notOptional) : ''
  }`

  return (
    <div className={classnames(styles.container, "dark:border-zinc-700")}>
      <div className={styles.labelContainer}>
        <div className={tagClasses}>{t(tagLabel)}</div>
        <div className={styles.label}>{modifier.name}</div>
        {modifier.description && <div className={styles.description}>{modifier.description}</div>}
        {!!optionsIds?.length && (
          <div className={styles.selectionInfo}>
            <SelectionInfo min={minSelection} max={maxSelection} selected={optionsIds.length} />
          </div>
        )}
      </div>
      <div className={`${styles.options} ${modifier.isImageModifier ? styles.imageOptions : ''}`}>
        {options.map(option => (
          <Option
            optionsIds={optionsIds}
            onChange={onChange}
            key={option._id}
            option={option}
            product={product}
            modifier={modifier}
            image={modifier.isImageModifier}
          />
        ))}
      </div>
    </div>
  )
}
