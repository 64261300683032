import {
  SelectProductActionsCartItemsQuery,
  SelectProductActionsCartItemsQueryVariables
} from '@data/__generated__/types.main'
import isServerSide from '@helpers/misc/isServerSide'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export default function useSelectProductCartItems() {
  const websiteId = useWebsiteId()
  const {preferences} = useApolloQuery<
    SelectProductActionsCartItemsQuery,
    SelectProductActionsCartItemsQueryVariables
  >({
    query: gql`
      query selectProductActionsCartItems($websiteId: ID!) {
        preferences: userPreferences(websiteId: $websiteId) {
          _id
          menuId
          cart {
            items {
              _id
              productId
              amount
              isOutOfStock
            }
          }
        }
      }
    `,
    omit: isServerSide(),
    variables: {
      websiteId
    }
  })

  return preferences
}
