import React from 'react'
import MultilineText from '@components/MultilineText'
import PromotionTag from '@components/PromotionTag'
import {EVENTS} from '@helpers/analytics/events'
import {BrowseTaxonomies} from '@helpers/analytics/events/browse'
import {sendEvent} from '@helpers/analytics/sendEvents'
import useTrackEcommerceEvent, {ECOMMERCE_EVENTS} from '@helpers/track/useTrackEcommerceEvent'
import useAnalyticLocation from '@hooks/useAnalyticLocation'
import useEffect from '@hooks/useEffect'
import useEventsBaseProperties from '@hooks/useEventsBaseProperties'
import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'
import TrackProductView from '@page-components/AnalyticsV2/tracking-components/TrackProductView'
import WarningBox from '@page-components/Order/warningBox'
import {useTranslation} from 'next-i18next'
import InformativeStamps from 'src/components/InformativeStampsV2'

import Modifiers from './Modifiers'
import Track from './Track'

const translatePrefix = 'order.products.productsContent.selectProduct.inner'

export interface Props {
  product: any
  state: any
  onChange: (state: any) => void
}

const OutOfStockOrUnavailableWarning = ({product}) => {
  const {t} = useTranslation('website')
  return product.isOutOfStock || !product.availabilityAt?.available ? (
    <WarningBox leftAligned>
      {product.isOutOfStock
        ? t(`${translatePrefix}.actions.labelProductOutOfStock`)
        : product.availabilityAt?.notAvailableMessage ||
          t(`${translatePrefix}.actions.labelProductNotAvailable`)}
    </WarningBox>
  ) : null
}

export default function Details(props: Props) {
  const {product} = props
  const {t} = useTranslation('website')
  const isAnalyticsV2Avail = useIsAnalyticsV2Avail()
  const trackEcommerceEvent = useTrackEcommerceEvent()
  const informativeStampsList = product?.informativeStamps
  const eventBaseProperties = useEventsBaseProperties()
  const actionLocation = useAnalyticLocation('selectProduct')

  useEffect(() => {
    if (!isAnalyticsV2Avail) {
      trackEcommerceEvent(ECOMMERCE_EVENTS.VIEWCONTENT, {product})
      sendEvent<BrowseTaxonomies['productSelected']>(EVENTS.browse.productSelected, {
        productName: product.name,
        actionLocation: actionLocation,
        productId: product._id,
        ...eventBaseProperties
      })
    }
  }, [product._id])

  return (
    <div className="flex flex-1 flex-col">
      <div className="flex-1 overflow-auto">
        <div className="flex flex-col px-1 pt-6">
          <h1 className="mb-3 px-5 text-2xl font-bold">{product.name}</h1>
          <PromotionTag product={product} showPrice={false} />
          {informativeStampsList && !!informativeStampsList.length && (
            <div className="mb-3">
              <InformativeStamps stamps={informativeStampsList} iconOnly={false} />
            </div>
          )}
          <OutOfStockOrUnavailableWarning product={product} />
          <p className="descriptionTextColor px-5 dark:text-zinc-200">
            <MultilineText>{product.description}</MultilineText>
            {!!product.maxPurchaseQuantity && (
              <span>
                {`* ${t(`${translatePrefix}.details.maxPurchaseQuantity`, {
                  max: product.maxPurchaseQuantity
                })}`}
              </span>
            )}
          </p>
          <Modifiers product={product} state={props.state} onChange={props.onChange} />
        </div>
      </div>
      {isAnalyticsV2Avail && <TrackProductView product={product} />}
      {!isAnalyticsV2Avail && <Track productId={product._id} />}
    </div>
  )
}
