import React from 'react'

import Checkbox from './Checkbox'
import Count from './Count'
import Radio from './Radio'

export default function Input(props) {
  const {option, modifier} = props

  if (modifier.max === 1) {
    return <Radio {...props} color="blue" />
  }

  if (option.max === 1) {
    return <Checkbox {...props} />
  }

  return <Count {...props} />
}
