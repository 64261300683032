import {useContext, useEffect} from 'react'
import PageMetaContext from '@helpers/websites/PageMetaContext'

export default function useMeta(subtitle, description, image?, schema?) {
  const {setSubtitle, setDescription, setImage, setSchema} = useContext(PageMetaContext)
  useEffect(() => {
    setSubtitle(subtitle)
    setDescription(description)
    setImage(image)
    setSchema(schema)
    return () => {
      setSubtitle()
      setDescription()
      setImage()
      setSchema()
    }
  }, [])
}
