import React, {Fragment} from 'react'

export default function MultilineText(props) {
  if (!props.children) return null
  const lines = props.children.split('\n')
  return (
    <Fragment>
      {lines.map((line, index) => (
        <Fragment key={index}>
          {line}
          {index !== lines.length - 1 && <br />}
        </Fragment>
      ))}
    </Fragment>
  )
}
