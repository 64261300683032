import React from 'react'
import {useTranslation} from 'next-i18next'

import styles from './styles.module.css'

export default function SelectionInfo(props) {
  const {min, max, selected} = props
  const {t} = useTranslation('website', {
    keyPrefix:
      'order.products.productsContent.selectProduct.inner.details.modifiers.modifier.selectionInfo'
  })

  // only display information for multiple selection Modifiers.
  if (max === 1) return null

  const infiniteMax = max === 0 || max == null

  const rangeInfo = () => {
    // return if display range is not feasible.
    if (!infiniteMax && min > max) return null

    // user understands that the min is 1.
    if (min <= 1) return null

    if (min === max) {
      return <div>{t('labelSelectMin', {min})}</div>
    }

    return <div>{t('labelSelectAtLeast', {min})}</div>
  }

  const selectedInfo = () => {
    if (selected === 0) return null

    if (selected === 1) {
      return <div className={styles.selected}>{t('labelSelectedProduct', {selected})}</div>
    }

    return <div className={styles.selected}>{t('labelSelectedProducts', {selected})}</div>
  }

  return (
    <>
      {rangeInfo()}
      {selectedInfo()}
    </>
  )
}
