import {deleteCartItemMutation} from '@data/mutations/cart/deleteCartItem.preferences'
import {useRefetchCart} from '@data/queries/preferences/refetchCart'
import useMessage from '@hooks/useMessage'
import useWebsiteId from '@hooks/useWebsiteId'
import {clientNames} from '@providers/services'
import {useMutate} from 'apollo-hooks'

export default function useDelete() {
  const mutate = useMutate()
  const showMessage = useMessage()
  const refetchCart = useRefetchCart()
  const websiteId = useWebsiteId()

  return async cartItemId => {
    try {
      await mutate({
        mutation: deleteCartItemMutation,
        clientName: clientNames.preferences,
        variables: {cartItemId, websiteId}
      })
      await refetchCart()
    } catch (error) {
      showMessage(error)
    }
  }
}
