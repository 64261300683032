import getMissingModifier from './getMissingModifier'
import getPricePerItem from './getPricePerItem'

export default function getResume(props, state, product) {
  const missingModifier = getMissingModifier(product, state)
  const amount = state.amount
  const unitPrice = getPricePerItem(product, state, true)
  const baseUnitPrice = getPricePerItem(product, state, false)
  return {
    ready: !missingModifier,
    missingModifier,
    price: amount * unitPrice,
    basePrice: amount * baseUnitPrice,
    amount,
    unitPrice,
    baseUnitPrice
  }
}
