import React from 'react'
import FormatNumber from '@i18n/formatNumber/Component'
import {useTranslation} from 'next-i18next'

import styles from './styles.module.css'

interface PromotionProduct {
  availabilityAt?: ProductAvailabilityAt
  promotions?: Array<any>
}
interface ProductAvailabilityAt {
  finalPrice?: number | null
}
interface PromotionTagProps {
  product: PromotionProduct
  showPrice?: boolean
}

export default function PromotionTag({product, showPrice = true}: PromotionTagProps) {
  const {t} = useTranslation('website', {
    keyPrefix: 'promotionType'
  })
  const {availabilityAt, promotions} = product
  if (!promotions?.length) return null
  if (!availabilityAt) return null
  const {finalPrice} = availabilityAt

  const pricing = {
    price: finalPrice,
    promotionType: promotions[0].promotionType
  }

  return (
    <>
      {showPrice && <FormatNumber value={pricing.price} />}
      <div className={styles.promotion}>{t(pricing.promotionType)}</div>
    </>
  )
}
