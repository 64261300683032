import React from 'react'
import classnames from '@helpers/misc/classnames'

export interface WarningBoxProps {
  children?: React.ReactNode
  leftAligned?: boolean
}

export default function WarningBox(props: WarningBoxProps) {
  return (
    <div
      className={classnames(
        'w-full max-w-xs flex',
        props.leftAligned ? 'justify-start' : 'justify-center'
      )}>
      <div className="p-2 mx-5 my-2 text-sm bg-amber-500 text-white text-left rounded">
        {props.children}
      </div>
    </div>
  )
}
