import ClientSuspense from '@components/ClientSuspense'
import useAnalyticLocation from '@hooks/useAnalyticLocation'
import {useEffect} from 'react'
import useLazyAnalyticsV2 from '../hooks/useLazyAnalyticsV2'

export interface TrackProductViewInnerProps {
  product: any
}

function TrackProductViewInner(props: TrackProductViewInnerProps) {
  const {product} = props
  const actionLocation = useAnalyticLocation('selectProduct')
  const analyticsV2 = useLazyAnalyticsV2()
  useEffect(() => {
    if (!product._id) return
    analyticsV2.trackProductViewOnAll(product, {actionLocation: actionLocation})
  }, [product._id])

  return null
}

export default function TrackProductView(props: Readonly<TrackProductViewInnerProps>) {
  return (
    <ClientSuspense fallback={null}>
      <TrackProductViewInner {...props} />
    </ClientSuspense>
  )
}
