import React from 'react'
import {MdRadioButtonChecked, MdRadioButtonUnchecked} from 'react-icons/md'

import styles from './styles.module.css'

export default function Radio(props) {
  const Icon = props.count ? MdRadioButtonChecked : MdRadioButtonUnchecked

  return (
    <div className={styles.container} onClick={props.select}>
      <Icon size={24} />
    </div>
  )
}
