import React from 'react'
import clone from 'lodash/clone'

import Input from './Input'
import Price from './Price'

import styles from './styles.module.css'

export default function Option(props) {
  const {modifier, option, optionsIds, onChange, image} = props
  const count = optionsIds.filter(optionId => optionId === option._id).length

  let canAdd = true

  if (option.max && count >= option.max) {
    canAdd = false
  }

  if (modifier.max && optionsIds.length >= modifier.max) {
    canAdd = false
  }

  if (option.isOutOfStock) {
    canAdd = false
  }

  const add = () => {
    if (!canAdd) return
    const value = clone(optionsIds)
    value.push(option._id)
    onChange(value)
  }

  const subtract = () => {
    if (option.isOutOfStock) return

    const value = clone(optionsIds)

    const index = value.indexOf(option._id)
    if (index > -1) {
      value.splice(index, 1)
    }

    onChange(value)
  }

  const toggle = () => {
    if (optionsIds.includes(option._id)) {
      subtract()
    } else {
      add()
    }
  }

  const select = () => {
    if (optionsIds.includes(option._id)) {
      subtract()
    } else {
      onChange([option._id])
    }
  }

  const defaultAction = modifier.max === 1 ? select : option.max === 1 ? toggle : add

  return (
    <div
      className={`${option.isOutOfStock ? styles.optionOutOfStock : styles.optionContainer} ${
        image ? styles.optionContainerImage : ''
      }`}>
      {image ? (
        <div className={`${styles.imageContainer} ${count ? styles.imageContainerSelected : ''}`}>
          <img
            src={option.image && option.image[0] ? option.image[0].imageLarge : null}
            onClick={defaultAction}
            alt={option.name}
            className={`${styles.optionImage} ${select ? styles.optionImageSelected : ''}`}
          />
          <p>{option.name}</p>
          <p>
            <Price {...props} />
          </p>
          <div className={styles.numberContainer}>
            {count > 0 ? (
              <div className={styles.numberMinus} onClick={subtract}>
                -
              </div>
            ) : null}
            {count ? <div className={styles.numberItems}>{count}</div> : null}

            <div className={`${styles.numberPlus} ${canAdd ? '' : styles.hideInput}`} onClick={add}>
              +
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.optionLabel} onClick={defaultAction} aria-label={option.name}>
            {option.name}
            <Price {...props} />
          </div>
        </>
      )}
      <div className={`${image ? styles.hideInput : ''}`}>
        <Input
          {...props}
          count={count}
          add={add}
          subtract={subtract}
          toggle={toggle}
          select={select}
          canAdd={canAdd}
        />
      </div>
    </div>
  )
}
