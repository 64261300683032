import React from 'react'

export default function LoadingModal(props) {
  return (
    <div className="h-full w-full self-center text-center">
      <div className="flex flex-col md:flex-row h-full animate-pulse duration-200">
        <div className="md:flex-1 h-1/3 sm:h-1/2 md:h-full bg-gray-200"></div>
        <div className="md:flex-1 h-2/3 sm:h-1/2 md:h-full">
          <div className="p-4 pb-12 my-8 flex h-full flex-col justify-between">
            <div className="flex flex-col gap-4">
              <div className="w-64 h-8 mb-4 bg-gray-200"></div>
              <div className="w-100 h-4 bg-gray-200"></div>
              <div className="w-100 h-4 bg-gray-200"></div>
              <div className="w-100 h-4 bg-gray-200"></div>
            </div>
            <div className="w-full h-12 bg-gray-200"></div>
          </div>
        </div>
      </div>
    </div>
  )
}
