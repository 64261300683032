import React from 'react'

import promotionTypeActions from './promotionTypeActions'

export default function PromotionDetail(props) {
  const {product, amount} = props
  const {promotions} = product

  if (!promotions?.length) return null
  const promotion = promotions[0]
  const promotionActions = promotionTypeActions[promotion.promotionType]
  if (!promotionActions) return null
  const calculate = promotionActions.calculate(amount)
  return <>{promotionActions.text(calculate)}</>
}
