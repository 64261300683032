import React from 'react'

export const CartLoadingContext = React.createContext<{
  loading: boolean
  setLoading: (loading: boolean) => void
}>({
  loading: false,
  setLoading: () => {}
})

export const useCartLoading = () => React.useContext(CartLoadingContext)

export const CartLoadingProvider = ({children}: {children: React.ReactNode}) => {
  const [loading, setLoading] = React.useState(false)
  return (
    <CartLoadingContext.Provider value={{loading, setLoading}}>
      {children}
    </CartLoadingContext.Provider>
  )
}
