import React from 'react'
import {MdKeyboardArrowDown} from 'react-icons/md'
import Transition from 'react-transition-group/Transition'
import useEffect from '@hooks/useEffect'
import useRef from '@hooks/useRef'
import useState from '@hooks/useState'
import useWidth from '@hooks/useWidth'

import styles from './styles.module.css'

export default function Layout(props) {
  const {image, details, actions, promotionDetail = null} = props
  const width = useWidth()
  const scrollableContainer = useRef(null)
  const [showArrow, setShowArrow] = useState(false)

  useEffect(() => {
    const element = scrollableContainer.current
    if (!element) return

    const hasVerticalScrollbar = element.scrollHeight > element.clientHeight

    setShowArrow(hasVerticalScrollbar)
  }, [])

  const onScroll = event => {
    setShowArrow(false)
  }

  const arrow = (
    <Transition in={showArrow} timeout={500}>
      {state => (
        <div className={styles[`arrow_${state}`]}>
          <MdKeyboardArrowDown size={50} />
        </div>
      )}
    </Transition>
  )

  return width <= 768 ? (
    <div className="flex flex-col h-full">
      <div className="flex-1 overflow-auto relative" ref={scrollableContainer} onScroll={onScroll}>
        {arrow}
        {image}
        {details}
      </div>
      {actions}
    </div>
  ) : (
    <div className="flex flex-1 w-full h-full">
      <div className="flex-1 w-1/2">{image}</div>
      <div className="flex flex-1 flex-col">
        <div
          className="flex-1 overflow-auto relative dark:bg-zinc-800"
          ref={scrollableContainer}
          onScroll={onScroll}>
          {arrow}
          {details}
        </div>
        {actions}
        {promotionDetail ? promotionDetail : null}
      </div>
    </div>
  )
}
