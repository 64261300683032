import React from 'react'
import classnames from '@helpers/misc/classnames'
import useIsDarkMode from '@hooks/useIsDarkMode'
import formatNumber from '@i18n/formatNumber'
import {useTranslation} from 'next-i18next'

import styles from './styles.module.css'

export default function Price(props) {
  const {option} = props
  const {t} = useTranslation('generic', {keyPrefix: 'fields'})
  const availability = option.availabilityAt
  const isDarkMode = useIsDarkMode()
  const outOfStockClass = isDarkMode
    ? classnames(styles.outOfStock, styles.outOfStockDark)
    : styles.outOfStock

  if (option.isOutOfStock) return <div className={outOfStockClass}>{t('outOfStock')}</div>
  if (!availability.price) return null
  return (
    <div className="font-semibold text-black dark:text-white ">
      {availability.price >= 0 ? '' : '-'} {formatNumber(Math.abs(availability.price), 'money')}
    </div>
  )
}
