import gql from 'graphql-tag'

export default gql`
  mutation updateCartItem(
    $productId: ID
    $amount: Float
    $modifiers: [CartItemOptionInput]
    $comment: String
    $menuId: ID
    $cartItemId: ID
  ) {
    item: updateCartItem(
      cartItemId: $cartItemId
      productId: $productId
      amount: $amount
      modifiers: $modifiers
      comment: $comment
      menuId: $menuId
    ) {
      _id
      totalPrice(includeDiscount: true)
      unitPrice
    }
  }
`
