import React from 'react'
import useOnEvent from 'react-app-events/lib/useOnEvent'
import ObjectField from '@components/fields/ObjectField'
import sleep from '@helpers/misc/sleep'
import useRef from '@hooks/useRef'
import useState from '@hooks/useState'
import useWidth from '@hooks/useWidth'
import {Field, Form} from 'simple-react-form'

import getFilteredModifiers from '../../getFilteredModifiers'

import Comments from './Comments'
import Modifier from './Modifier'
import useMenuConfiguration from './useMenuConfiguration'

export default function Modifiers(props) {
  const {product, state, onChange} = props
  const menuConfiguration = useMenuConfiguration()
  const isMobile = useWidth() < 768
  const refs = useRef({}).current
  const [shakingId, setShakingId] = useState<string | void>()

  useOnEvent('scrollToRequiredModifier', async modifier => {
    const ref = refs[modifier._id]
    if (!ref) return
    ref.scrollIntoView({
      behavior: 'smooth'
    })

    setShakingId(modifier._id)
    await sleep(1500)
    setShakingId(null)
  })

  if (!product.availabilityAt) return null

  const modifiers = getFilteredModifiers(product.modifiers, state)

  return (
    <div>
      <Form state={state} onChange={onChange}>
        {modifiers.map((modifier, index) => (
          <div
            key={modifier._id}
            className={`animated ${shakingId === modifier._id ? 'shake' : ''}`}
            ref={ref => (refs[modifier._id] = ref)}>
            <Field fieldName={`modifiers.${modifier.index}`} type={ObjectField}>
              <Field fieldName="optionsIds" type={Modifier} modifier={modifier} product={product} />
            </Field>
          </div>
        ))}
        {'blockCommentsOnProducts' in menuConfiguration &&
        menuConfiguration.blockCommentsOnProducts ? null : (
          <Field fieldName="comment" type={Comments} />
        )}
      </Form>
      <div style={{height: 40}} />
    </div>
  )
}
