import {SelectProductActionsCartItemsQuery} from '@data/__generated__/types.main'
import {EVENTS} from '@helpers/analytics/events'
import {PurchaseTaxonomies} from '@helpers/analytics/events/purchase'
import {sendEvent} from '@helpers/analytics/sendEvents'
import sleep from '@helpers/misc/sleep'
import useAnalyticLocation from '@hooks/useAnalyticLocation'
import useEventsBaseProperties from '@hooks/useEventsBaseProperties'

import useDelete from './useDelete'

import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'
import {useTrackProductEdit} from '@page-components/AnalyticsV2/tracking-hooks/useTrackProductEdit'
import styles from './styles.module.css'

interface DeleteProps {
  menuId: SelectProductActionsCartItemsQuery['preferences']['menuId']
  cartItem: SelectProductActionsCartItemsQuery['preferences']['cart']['items'][0]
  close: () => void
}

export default function Delete(props: Readonly<DeleteProps>) {
  const {close, cartItem, menuId} = props
  const deleteItem = useDelete()
  const baseEventProperties = useEventsBaseProperties()
  const actionLocation = useAnalyticLocation('editProduct')
  const isAnalyticsV2Avail = useIsAnalyticsV2Avail()
  const trackProductEdit = useTrackProductEdit()

  const remove = async () => {
    try {
      close()
      await sleep(200)
      await deleteItem(cartItem._id)
      if (!isAnalyticsV2Avail) {
        sendEvent<PurchaseTaxonomies['productEdited']>(EVENTS.purchase.productEdited, {
          ...baseEventProperties,
          productEditAction: 'delete',
          productId: cartItem?.productId,
          productAmount: 0,
          isAmountEdited: false,
          isOutOfStock: cartItem?.isOutOfStock ?? false,
          menuId: menuId ?? '',
          actionLocation: actionLocation,
        })
      } else {
        trackProductEdit.track({item: {...cartItem, amount: 0}, menuId})
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className={styles.container}>
      <a className={styles.button} onClick={remove}>
        Eliminar
      </a>
    </div>
  )
}
