import {i18n} from 'next-i18next'

import styles from './styles.module.css'

const promotionTypeActions = {
  buyOneGiveOne: {
    calculate: amount => {
      const promotionAmountRes = amount % 2
      return {
        promotionAmountRes,
        promotionAmount: (amount - promotionAmountRes) / 2
      }
    },
    text: ({promotionAmountRes, promotionAmount}) => {
      return (
        <div className={styles.container}>
          {!!promotionAmount && (
            <div className={styles.hasPromotion}>
              {i18n.t('promotionDetails.hasPromotion', {
                promotionCount: promotionAmount * 2,
                promotionAmount,
                ns: 'website'
              })}
            </div>
          )}
          {!!promotionAmountRes && (
            <div className={styles.carryMore}>
              {i18n.t('promotionDetails.carryMore', {
                missingItems: 2 - promotionAmountRes,
                ns: 'website'
              })}
            </div>
          )}
        </div>
      )
    }
  }
}

export default promotionTypeActions
