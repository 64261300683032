import ClientSuspense from '@components/ClientSuspense'
import classnames from '@helpers/misc/classnames'
import isServerSide from '@helpers/misc/isServerSide'
import useDisableScroll from '@hooks/useDisableScroll'
import useIsDarkMode from '@hooks/useIsDarkMode'
import useKeyboardEvent from '@hooks/useKeyboardEvent'
import {useSetSelectedProductId} from '@page-components/OrderV2/useSelectedProduct'
import React, {Suspense, useCallback, useMemo} from 'react'
import ClickOutside from 'react-click-outside'
import ReactDOM from 'react-dom'
import {MdClose} from 'react-icons/md'

import Inner from './Inner'
import LoadingModal from './Loading'

export interface SelectProductProps {
  product: {_id?: string}
  close: () => void
  open: boolean
  onAdd?: () => void
  allowEdit?: boolean
  cartItem?: any
}

function SelectProduct(props: SelectProductProps) {
  const {product, close: closeModal, open} = props
  const setSelectedProductId = useSetSelectedProductId()

  const handleClose = useCallback(() => {
    if (!open) return
    setSelectedProductId(null)

    closeModal()
  }, [open, closeModal, setSelectedProductId])

  const handleClickOutside = () => {
    if (document.getElementById('v3_modal')) return
    // This is hacky, but couldn't think of a better way to prevent the click outside when there are two modals open
    handleClose()
  }

  useKeyboardEvent('escape', () => {
    handleClose()
  })

  const productMemoized = useMemo(() => product, [product?._id])

  useDisableScroll(open)
  const isDarkMode = useIsDarkMode()

  const content = (
    <div className={classnames({dark: isDarkMode})}>
      <div
        className={`fixed z-[10000] inset-0 flex justify-center items-end md:items-center overflow-hidden ${
          open
            ? 'transition-colors ease-in duration-200 visible bg-black/[0.7] dark:bg-black/[0.9]'
            : 'invisible'
        }`}
      >
        <ClickOutside
          onClickOutside={handleClickOutside}
          className={`
              backgroundColor textColor relative top-[10vh] flex flex-col w-full md:w-[calc(100vw-40px)] max-w-[1200px] h-full md:h-[600px] mt-5 md:mt-0 max-h-[98dvh] overflow-hidden
              md:rounded-2xl md:flex-row ${
                open
                  ? 'top-0 transition-all ease-in duration-200 opacity-100 -translate-y-[10vh]'
                  : 'opacity-20'
              }`}
        >
          <div
            className={classnames(
              'absolute right-4 top-3 cursor-pointer z-[1200] bg-white w-30 h-30 flex items-center justify-center rounded-full',
              'dark:bg-zinc-200',
            )}
            onClick={handleClose}
          >
            <MdClose size={30} className="dark:fill-current dark:text-zinc-800 text-black" />
          </div>

          {product?._id ? (
            <>
              <Suspense fallback={<LoadingModal />}>
                <Inner
                  key={product._id}
                  product={productMemoized}
                  close={handleClose}
                  open={open}
                />
              </Suspense>
            </>
          ) : (
            <LoadingModal />
          )}
        </ClickOutside>
      </div>
    </div>
  )

  if (isServerSide()) {
    return content
  }
  return ReactDOM.createPortal(content, document.querySelector('#modal-root'))
}

export default function WrappedSelectProduct(props: SelectProductProps) {
  return (
    <ClientSuspense fallback={null}>
      <SelectProduct {...props} />
    </ClientSuspense>
  )
}
