import React from 'react'
import IconImage from '@components/Image'
import classnames from '@helpers/misc/classnames'
import Tooltip from '@packages/justo-parts/lib/components/Tooltip'
import icon from 'src/page-components/Order/Products/ProductsContent/Category/constants'

function informativeStamps({stamps, iconOnly}) {
  if (!stamps?.length) return null
  const stampsRow = stamps.map((stamp, index) => {
    const iconData = icon[stamp]
    if (!iconData) return null
    if (iconOnly) {
      return (
        <div className="flex flex-row p-0.5 items-center" key={index}>
          <Tooltip content={iconData.label}>
            <div className="flex items-center">
              <IconImage
                className="dark:brightness-[5]"
                alt={iconData.label}
                src={iconData.URL}
                width="16"
                height="16"
              />
            </div>
          </Tooltip>
        </div>
      )
    }
    return (
      <div
        className="flex flex-row rounded-3xl gap-x-1 bg-gray-100 dark:bg-zinc-700 px-2 py-0.5 items-center whitespace-nowrap"
        key={index}>
        <IconImage
          className="inline-block dark:brightness-[4] h-4 w-4"
          key={index}
          alt={iconData.label}
          src={iconData.URL}
          width="16"
          height="16"
          layout="fixed"
        />
        <div className="inline-block text-sm align-text-top">{iconData.label}</div>
      </div>
    )
  })

  return (
    <div
      className={classnames('flex flex-row flex-wrap justify-start', {
        'flex-none': iconOnly,
        'gap-y-2 gap-x-3 px-5': !iconOnly
      })}>
      {stampsRow}
    </div>
  )
}

export default informativeStamps
