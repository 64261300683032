import getFilteredModifiers from './getFilteredModifiers'
import getRemainingOptionsCount from './getRemainingOptionsCount'

export default function getMissingModifiers(product, state) {
  const modifiers = product?.modifiers || []
  if (!modifiers.length) return

  const filteredModifiers = getFilteredModifiers(modifiers, state)

  for (const modifier of filteredModifiers) {
    if (modifier.optional && !modifier.min) continue

    const stateModifier = state.modifiers.find(item => item.modifierId === modifier._id)

    if (modifier.min) {
      const remainingOptions = getRemainingOptionsCount(modifier, stateModifier.optionsIds.length)
      if (remainingOptions > 0) {
        return modifier
      } else {
        continue
      }
    }

    if (!stateModifier.optionsIds.length) {
      return modifier
    }
  }
}
