import {useRefetchCart} from '@data/queries/preferences/refetchCart'
import {EVENTS} from '@helpers/analytics/events'
import {PurchaseTaxonomies} from '@helpers/analytics/events/purchase'
import {sendEvent} from '@helpers/analytics/sendEvents'
import useTrackEcommerceEvent, {ECOMMERCE_EVENTS} from '@helpers/track/useTrackEcommerceEvent'
import useAnalyticLocation from '@hooks/useAnalyticLocation'
import useEventsBaseProperties from '@hooks/useEventsBaseProperties'
import useMessage from '@hooks/useMessage'
import useIsAnalyticsV2Avail from '@page-components/AnalyticsV2/hooks/useIsAnalyticsV2Available'
import {useCartLoading} from '@page-components/Order/CartLoadingContext'
import {useSetSelectedProductId} from '@page-components/OrderV2/useSelectedProduct'
import {useMutate} from 'apollo-hooks'
import fireEvent from 'react-app-events/lib/fireEvent'

import useLazyAnalyticsV2 from '@page-components/AnalyticsV2/hooks/useLazyAnalyticsV2'
import mutation from './mutation'
import mutationEdit from './mutationEdit'

export default function useAdd() {
  const mutate = useMutate()
  const showMessage = useMessage()
  const setSelectedProductId = useSetSelectedProductId()
  const trackEcommerceEvent = useTrackEcommerceEvent()
  const {setLoading: setCartLoading} = useCartLoading()
  const refetchCart = useRefetchCart()
  const baseEventsProperties = useEventsBaseProperties()
  const actionLocation = useAnalyticLocation('purchase')
  const isAnalyticsV2Avail = useIsAnalyticsV2Avail()
  const analyticsV2 = useLazyAnalyticsV2()

  // TODO (Important): Refactor and set types
  return async (
    props,
    state,
    product,
    resume,
    opts: {setLoading?: (boolean) => void; keepLoadingOnFinish?: boolean} = {},
  ) => {
    const {setLoading: setLoadingOpts, keepLoadingOnFinish} = opts

    const setLoading = (loading: boolean) => {
      if (setLoadingOpts) setLoadingOpts(loading)
      setCartLoading(loading)
    }

    let success = false

    if (resume.missingModifier) {
      fireEvent('scrollToRequiredModifier', resume.missingModifier)
      return
    }

    setLoading(true)
    try {
      const variables = {
        productId: props.product._id,
        amount: state.amount,
        modifiers: state.modifiers,
        comment: state.comment,
        menuId: props.menuId,
      }

      if (props.cartItem) {
        await mutate({
          mutation: mutationEdit,
          variables: {
            cartItemId: props.cartItem._id,
            ...variables,
          },
        })
      } else {
        const {item} = await mutate({
          mutation,
          variables,
        })

        await refetchCart()
        if (isAnalyticsV2Avail) {
          analyticsV2.trackAddToCartOnAll(
            {...product, state},
            {actionLocation, menuId: props.menuId},
          )
        } else {
          trackEcommerceEvent(ECOMMERCE_EVENTS.ADDTOCART, {product, item, amount: state.amount})
          const modifiers = product.modifiers ?? []
          const requiredModifiers = modifiers.filter(modifier => !modifier.optional)
          const productDiscount = Math.round(
            product.availabilityAt?.basePrice - product.availabilityAt?.finalPrice,
          )

          sendEvent<PurchaseTaxonomies['productAdded']>(EVENTS.purchase.productAdded, {
            categoryId: product.categories?.length ? product.categories[0]._id : '',
            categoryName: product.categories?.length ? product.categories[0].name : '',
            productName: product.name,
            productId: props.product?._id,
            productDescription: product.description,
            productAmount: state.amount,
            fullPrice: product.availabilityAt?.basePrice,
            productDiscount: productDiscount > 0 ? productDiscount : 0,
            productTotalPrice: (product.availabilityAt?.finalPrice ?? 0) * state.amount,
            amountRequiredModifiers: requiredModifiers.length,
            amountOptionalModifiers: modifiers.length - requiredModifiers.length,
            selectedOptionalModifiers: state.modifiers?.length - requiredModifiers.length,
            actionLocation: actionLocation,
            menuId: props.menuId,
            ...baseEventsProperties,
          })
        }
      }

      if (props.onAdd) {
        props.onAdd()
      }

      setSelectedProductId(null)
      props.close()
      success = true
    } catch (error) {
      showMessage(error)
    }

    setCartLoading(false)
    // In some cases, we want to keep showing the loading state until the location changes
    if (success && !keepLoadingOnFinish) setLoading(false)
  }
}
